import React from 'react'
import styled from '@emotion/styled'
import { colors } from '../../styles/variables'

export interface CheckboxProps {
  field: {
    name: string
    value: boolean
    onChange: () => void
    onBlur: () => void
  }
  form: {
    errors: any
    touched: any
    setFieldValue: () => void
  }
  id: string
  label: React.ReactNode
  className: string
  [key: string]: any
}

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledSwitchOuter = styled.label`
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
  margin-right: 0.75rem;
`

interface StyledSwitchProps {
  error?: boolean
}

const StyledSwitch = styled.span<StyledSwitchProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.error ? colors.warning : colors.gray.light)};
  transition: 0.4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`

interface StyledCheckboxProps {
  error?: boolean
}

const StyledCheckbox = styled.input<StyledCheckboxProps>`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + ${StyledSwitch} {
    background-color: ${props => (props.error ? colors.warning : colors.blue.darkest)};
  }

  :checked + ${StyledSwitch}:before {
    transform: translateX(24px);
  }
`

const StyledLabel = styled.label`
  line-height: 1.25;
  display: block;
  font-size: 0.825rem;
`

// Checkbox input
const Checkbox: React.FC<CheckboxProps> = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <StyledCheckboxWrapper>
      <StyledSwitchOuter>
        <StyledCheckbox
          name={name}
          id={id}
          type="checkbox"
          value={name}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          className={className}
          {...props}
        />
        <StyledSwitch error={touched[name] && errors[name]} />
      </StyledSwitchOuter>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </StyledCheckboxWrapper>
  )
}

export default Checkbox
