import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import { colors } from '../styles/variables'
import useTranslation from './use-translation'
import Container from './container'
import Grid5 from './grid-5'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BorderTop from './border-top'
import BorderBottom from './border-bottom'

export interface IBlockAlternativesData {
  allAlternativesJson: {
    edges: {
      node: {
        url: string
        image: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      }
    }[]
  }
}

const StyledAlternatives = styled.div`
  background-color: ${colors.blue.medium};
`

const StyledHeadline = styled.h2`
  margin-bottom: 2rem;
`

const StyledGrid = styled(Grid5)`
  margin-top: 2rem;
  margin-bottom: 0.75rem;
`

const StyledAlternative = styled.a`
  position: relative;
  background-color: transparent;
  border: 8px solid transparent;
  border-image: url(/images/border-box-white.png) 12 12 repeat;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }

  > div {
    z-index: 1;
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const BlockAlternatives = () => {
  const {
    allAlternativesJson: { edges: alternatives }
  }: IBlockAlternativesData = useStaticQuery(query)

  const { alternatives_headline, alternatives_paragraph_1, alternatives_paragraph_2 } = useTranslation()
  return (
    <StyledAlternatives id="alternatives">
      <BorderTop />
      <Container>
        <StyledHeadline>{alternatives_headline}</StyledHeadline>
        <p>
          <strong>{alternatives_paragraph_1}</strong>
        </p>
        <p>{alternatives_paragraph_2}</p>
        <StyledGrid>
          {alternatives.map(({ node: alternative }) => (
            <StyledAlternative key={alternative.url} href={alternative.url} target="blank">
              <StyledImage image={alternative.image.childImageSharp.gatsbyImageData} alt={alternative.url} />
            </StyledAlternative>
          ))}
        </StyledGrid>
      </Container>
      <BorderBottom />
    </StyledAlternatives>
  )
}

export default BlockAlternatives

export const query = graphql`
  query AlternativesQuery {
    allAlternativesJson {
      edges {
        node {
          url
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
