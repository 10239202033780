import React from 'react'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'
import useTranslation from './use-translation'
import Container from './container'
import Grid2 from './grid-2'

const StyledMission = styled.div`
  background-color: ${colors.white};
`

const StyledHeadline = styled.h2`
  margin-bottom: 2rem;
`

const StyledList = styled.ul`
  padding-left: 1rem;
  > li {
    margin-bottom: 0.5rem;
  }
`

const BlockMission = () => {
  const {
    mission_headline,
    mission_paragraph_1,
    mission_paragraph_2,
    mission_paragraph_3,
    mission_paragraph_3_emphasis,
    mission_paragraph_4,
    mission_reason_1,
    mission_reason_2,
    mission_reason_3,
    mission_reason_4,
    mission_reason_5
  } = useTranslation()
  return (
    <StyledMission id="mission">
      <Container>
        <StyledHeadline>{mission_headline}</StyledHeadline>
        <Grid2>
          <div>
            <p>{mission_paragraph_1}</p>
            <p>
              <strong>{mission_paragraph_2}</strong>
            </p>
            <p>
              {mission_paragraph_3} <strong>{mission_paragraph_3_emphasis}</strong>
            </p>
          </div>
          <div>
            <p>{mission_paragraph_4}</p>
            <StyledList>
              <li>{mission_reason_1}</li>
              <li>{mission_reason_2}</li>
              <li>{mission_reason_3}</li>
              <li>{mission_reason_4}</li>
              <li>{mission_reason_5}</li>
            </StyledList>
          </div>
        </Grid2>
      </Container>
    </StyledMission>
  )
}

export default BlockMission
