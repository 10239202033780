import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { colors, mediaqueries } from '../styles/variables'
import useTranslation from './use-translation'
import Container from './container'
import BorderTop from './border-top'

export interface IBlockAboutData {
  file: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

const StyledAbout = styled.div`
  background-color: ${colors.blue.medium};
`

const StyledHeadline = styled.h2`
  margin-bottom: 2rem;
`

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${mediaqueries.md} {
    grid-template-columns: 110px 1fr;
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const BlockAbout = () => {
  const data: IBlockAboutData = useStaticQuery(query)
  const { about_headline, about_paragraph_1, about_paragraph_link } = useTranslation()

  return (
    <StyledAbout id="about">
      <BorderTop />
      <Container>
        <StyledHeadline>{about_headline}</StyledHeadline>
        <StyledGrid>
          <div style={{ display: 'flex' }}>
            <StyledImage image={data.file.childImageSharp.gatsbyImageData} alt="Clean Ocean Project" />
          </div>
          <div>
            <p>{about_paragraph_1}</p>
            <a href="http://www.cleanoceanproject.org/" target="blank">
              <strong>{about_paragraph_link}</strong>
            </a>
          </div>
        </StyledGrid>
      </Container>
    </StyledAbout>
  )
}

export default BlockAbout

const query = graphql`
  query BlockAboutQuery {
    file(relativePath: { eq: "logos/logo-cop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 110, height: 122)
      }
    }
  }
`
