import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
// import Link from './localized-link'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { colors, mediaqueries } from '../styles/variables'
import useTranslation from './use-translation'

export interface IBlockHeroData {
  logo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  stamp: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

const StyledHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 70vh;

  ${mediaqueries.md} {
    min-height: 80vh;
  }
`

const StyledBanderole = styled(Link)`
  position: relative;
  background-color: transparent;
  border: 8px solid transparent;
  border-image: url(/images/border-box-blue-light.png) 12 12 repeat;
  padding: 0.25rem;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.blue.light};
  }
  color: ${colors.blue.darkest};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  width: calc(100% - 1rem);
`

const StyledBanderoleInner = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;

  ${mediaqueries.md} {
    text-align: center;
    align-items: center;
  }
`

const StyledStampImage = styled(GatsbyImage)`
  flex-shrink: 0;
  margin: -8px 0.75rem -8px 0;
  z-index: 1;

  ${mediaqueries.md} {
    margin: -8px 0.75rem -8px -96px;
  }
`

const StyledParagraph = styled.p`
  margin-bottom: 8px;
`

const StyledButton = styled.span`
  background-color: ${colors.white};
  color: ${colors.blue.darkest};
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
`

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 25rem;
  padding: 0;
  text-align: center;
`

const StyledLogoImage = styled(GatsbyImage)`
  width: 300px !important;
  height: 293px !important;

  ${mediaqueries.md} {
    width: 400px !important;
    height: 391px !important;
  }

  img {
    object-fit: contain !important;
  }
`

const StyledHeadline = styled.h1`
  text-align: center;
`

const BlockHero = () => {
  const data: IBlockHeroData = useStaticQuery(query)
  const { banderole_paragraph, banderole_button, hero_headline } = useTranslation()

  return (
    <>
      <StyledBanderole to="/open-letter">
        <StyledStampImage image={data.stamp.childImageSharp.gatsbyImageData} alt="Stamp" />
        <StyledBanderoleInner>
          <StyledParagraph>{banderole_paragraph}</StyledParagraph>
          <StyledButton>{banderole_button}</StyledButton>
        </StyledBanderoleInner>
      </StyledBanderole>
      <StyledHero>
        <StyledContainer>
          <StyledLogoImage image={data.logo.childImageSharp.gatsbyImageData} alt="Clean Coffee Project Logo" />
          <StyledHeadline>{hero_headline}</StyledHeadline>
        </StyledContainer>
      </StyledHero>
    </>
  )
}

export default BlockHero

const query = graphql`
  query BlockHeroQuery {
    logo: file(relativePath: { eq: "logos/logo-large.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        gatsbyImageData(layout: FIXED, width: 400, height: 391)
      }
    }
    stamp: file(relativePath: { eq: "media/george-stamp.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        gatsbyImageData(layout: FIXED, width: 96, height: 72)
      }
    }
  }
`
