import React from 'react'
import styled from '@emotion/styled'
import { mediaqueries } from '../styles/variables'

interface Grid5Props {
  children: React.ReactNode
  className?: string
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  ${mediaqueries.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${mediaqueries.md} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`

const Grid5: React.FunctionComponent<Grid5Props> = ({ children, className }) => {
  return <StyledGrid className={className}>{children}</StyledGrid>
}

export default Grid5
