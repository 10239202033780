import * as React from 'react'

import Page from '../components/page'
import BlockHero from '../components/block-hero'
import BlockPetition from '../components/block-petition'
import BlockMission from '../components/block-mission'
import BlockAlternatives from '../components/block-alternatives'
import BlockSupporters from '../components/block-supporters'
import BlockJoin from '../components/block-join'
import BlockAbout from '../components/block-about'
import styled from '@emotion/styled'

const StyledRule = styled.hr`
  margin: 0;
`

const IndexPage = () => (
  <Page>
    <BlockHero />
    <BlockPetition />
    <BlockMission />
    <BlockAlternatives />
    <BlockSupporters />
    <StyledRule />
    <BlockJoin />
    <BlockAbout />
  </Page>
)

export default IndexPage
