import * as React from 'react'
import styled from '@emotion/styled'

const StyledBorder = styled.div`
  width: 100%;
  height: 4px;
  background-image: url(/images/border-bottom.png);
  background-size: 1000px 4px;
`

const BorderBottom: React.FunctionComponent = () => {
  return <StyledBorder />
}

export default BorderBottom
