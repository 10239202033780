import React from 'react'
import styled from '@emotion/styled'
import { colors, dimensions } from '../styles/variables'
import useTranslation from './use-translation'
import Container from './container'
import Grid2 from './grid-2'
import BorderTop from './border-top'
import BorderBottom from './border-bottom'
import PetitionForm from './petition-form'

const StyledPetition = styled.div`
  background-color: ${colors.blue.medium};
`

const StyledHeadline = styled.h2`
  margin-bottom: 2rem;
`

const StyledCounter = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;

  > h1 {
    line-height: 0.575;
  }

  > span {
    line-height: 1.25;
    margin-top: -6px;
  }
`

const StyledSignatureCount = styled.h1`
  text-align: left;
  display: inline;
  margin: 0 0.75rem 0 0;
`

const StyledPetitionText = styled.div`
  background-color: ${colors.blue.dark};
  padding: ${dimensions.containerPadding.sm}rem;
  color: ${colors.white};
`

const BlockPetition = () => {
  const {
    petition_headline,
    petition_paragraph_1,
    petition_paragraph_2,
    petition_paragraph_3,
    petition_paragraph_3_link,
    petition_paragraph_4,
    petition_signatures_counter,
    petition_text
  } = useTranslation()

  const [signatureCount, setSignatureCount] = React.useState({ count: '', isFetching: false })

  React.useEffect(() => {
    let didCancel = false
    const fetchSignatureCount = async () => {
      try {
        const response = await fetch(
          'https://script.google.com/macros/s/AKfycbwZNo8_f4hnqvsb65NKf4qMM-M6_qEQ35PA29t5Y_KRYyyuVUlssfUc8j7C1zyc5uwLMw/exec',
          {
            method: 'GET'
            // mode: 'no-cors'
          }
        )
        const data = await response.json()
        if (!didCancel) setSignatureCount({ count: data.signatures, isFetching: false })
      } catch (e) {
        console.log(e)
        if (!didCancel) setSignatureCount({ count: '', isFetching: false })
      }
    }
    fetchSignatureCount()
    return () => {
      didCancel = true
    }
  }, [])

  return (
    <StyledPetition id="petition">
      <BorderTop />
      <Container>
        <StyledHeadline>{petition_headline}</StyledHeadline>
        <Grid2>
          <div>
            <p>{petition_paragraph_1}</p>
            <p>
              <strong>{petition_paragraph_2}</strong>
            </p>
            <p>
              {petition_paragraph_3}{' '}
              <a href="https://www.zerowasteeurope.eu/2011/05/coffee-capsules-and-zero-waste/" target="blank">
                {petition_paragraph_3_link}
              </a>
            </p>
            <p>{petition_paragraph_4}</p>
            <StyledCounter>
              <StyledSignatureCount>{signatureCount.count}</StyledSignatureCount>
              <span>{petition_signatures_counter}</span>
            </StyledCounter>
          </div>
          <div>
            <StyledPetitionText>{petition_text}</StyledPetitionText>
            <PetitionForm />
          </div>
        </Grid2>
      </Container>
      <BorderBottom />
    </StyledPetition>
  )
}

export default BlockPetition
