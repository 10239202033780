import React from 'react'
import styled from '@emotion/styled'
import { colors } from '../../styles/variables'

const StyledInputError = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${colors.warning};
  color: ${colors.white};
  text-align: center;

  p {
    font-size: 1.25rem;
    font-weight: bold;
    transform: translateY(-6px);
  }
`

const InputError: React.FC = () => (
  <StyledInputError>
    <p>×</p>
  </StyledInputError>
)

export default InputError
