import React from 'react'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'
import useTranslation from './use-translation'
import Container from './container'
import Grid5 from './grid-5'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BorderTop from './border-top'
import BorderBottom from './border-bottom'

export interface IBlockSupportersData {
  allSupportersJson: {
    edges: {
      node: {
        url: string
        image: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      }
    }[]
  }
}

const StyledSupporters = styled.div`
  background-color: ${colors.white};
`

const StyledHeadline = styled.h2`
  margin-bottom: 2rem;
`

const StyledGrid = styled(Grid5)`
  margin-top: 2rem;
`

const StyledSupporter = styled.a`
  position: relative;
  background-color: ${colors.white};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  img {
    filter: grayscale(100%);
    transition: all 0.25s ease !important;

    :hover {
      filter: grayscale(0%);
    }
  }
`

interface SupporterProps {
  node: {
    url: string
    image: any
  }
}

const BlockSupporters = () => {
  const {
    allSupportersJson: { edges: supporters }
  }: IBlockSupportersData = useStaticQuery(query)
  const { supporters_headline } = useTranslation()
  return (
    <StyledSupporters id="supporters">
      <BorderTop />
      <Container>
        <StyledHeadline>{supporters_headline}</StyledHeadline>
        <StyledGrid>
          {supporters.map(({ node: alternative }: SupporterProps) => (
            <StyledSupporter key={alternative.url} href={alternative.url} target="blank">
              <StyledImage image={alternative.image.childImageSharp.gatsbyImageData} alt={alternative.url} />
            </StyledSupporter>
          ))}
        </StyledGrid>
      </Container>
      <BorderBottom />
    </StyledSupporters>
  )
}

export default BlockSupporters

export const query = graphql`
  query SupportersQuery {
    allSupportersJson {
      edges {
        node {
          url
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
