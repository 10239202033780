import React from 'react'
import styled from '@emotion/styled'
import { mediaqueries } from '../styles/variables'

interface Grid2Props {
  children: React.ReactNode
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  ${mediaqueries.md} {
    grid-template-columns: 1fr 1fr;
  }
`

const Grid2: React.FunctionComponent<Grid2Props> = ({ children }) => {
  return <StyledGrid>{children}</StyledGrid>
}

export default Grid2
