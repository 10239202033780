import React from 'react'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'
import useTranslation from './use-translation'
import Container from './container'

const StyledJoin = styled.div`
  background-color: ${colors.white};
`

const StyledHeadline = styled.h2`
  margin-bottom: 2rem;
`

const BlockJoin = () => {
  const { join_headline, join_paragraph_1, join_paragraph_2, join_paragraph_3, join_paragraph_3_link } = useTranslation()
  return (
    <StyledJoin id="join">
      <Container>
        <StyledHeadline>{join_headline}</StyledHeadline>
        <p>
          <strong>{join_paragraph_1}</strong>
        </p>
        <p>{join_paragraph_2}</p>
        <p>
          {join_paragraph_3}{' '}
          <strong>
            <a href="mailto:info@cleancoffeeproject.org">{join_paragraph_3_link}</a>
          </strong>
        </p>
      </Container>
    </StyledJoin>
  )
}

export default BlockJoin
